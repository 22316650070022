<template>
  <v-dialog width="1080px" v-model="dialog" persistent>
    <v-card>
      <v-container class="pa-5">
        <v-row>
          <v-col cols="12">
            <!--Cancel-->
            <v-btn text class="float-right" @click="$emit('closeDialog')"
              ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
            >
            <!--Save-->
            <v-btn
              class="float-right"
              color="primary"
              @click="saveProducts()"
              :disabled="selectedProducts.length === 0"
              :loading="loadingBtn"
              >Add Products To Website ({{ selectedProducts.length }})</v-btn
            >
            <h2>Products</h2>
          </v-col>
        </v-row>
        <!--Alert-->
        <v-row>
          <v-col cols="12">
            <v-alert type="info" text v-if="!errorAlert">
              Select products from your ERP system to display them on your
              ecommerce website
            </v-alert>
            <!--Error message-->
            <v-alert type="error" text v-else>
              Oops! There was an error... Please try again <br />
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>
        <!--Data table-->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="filteredProducts"
              :headers="headers"
              :search="search"
              dense
              v-model="selectedProducts"
              :items-per-page="100"
              item-key="product_id"
              show-select
              :loading="loadingTable"
              loading-text="Loading products..."
              group-by="product_category"
            >
              <!--Filters + Search-->
              <template v-slot:top>
                <v-row>
                  <v-col md="4">
                    <v-autocomplete
                      v-model="categoryFilter"
                      append-icon="mdi-filter-variant"
                      placeholder="Filter categories"
                      dense
                      outlined
                      clearable
                      :items="filteredCategories"
                    ></v-autocomplete>
                  </v-col>
                  <!--Search-->
                  <v-col md="8">
                    <v-text-field
                      v-model="search"
                      placeholder="Search..."
                      append-icon="mdi-magnify"
                      dense
                      outlined
                      clearable
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <!--Group header-->
              <template v-slot:group.header="{ items }">
                <th colspan="9" class="blue-grey lighten-5">
                  {{ items[0].product_category }}
                </th>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import db from "../../../../components/firebaseInit";
import store from "../../store";
import { mapGetters, mapActions } from "vuex";
import { showSnackbar } from "../../../../globalActions";
export default {
  name: "ProductModal",
  props: ["dialog"],
  data() {
    return {
      products: [],
      selectedProducts: [],
      search: "",
      categoryFilter: "",
      headers: [
        { text: "Name", value: "product_name", align: "start" },
        { text: "Category", value: "product_category" },
        { text: "URL", value: "path" },
      ],
      loadingBtn: false,
      loadingTable: false,
      errorAlert: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters(["filteredCategories"]),
    filteredProducts() {
      const query = this.categoryFilter === "" || !this.categoryFilter;
      return query
        ? this.products
        : this.products.filter(
            (el) => el.product_category === this.categoryFilter
          );
    },
  },
  watch: {
    /*
      If a new category is added or removed from the website
      then reset the product Array so that the product list is retrieved again 
      to include the new categories
    */
    filteredCategories() {
      this.products = [];
    },
  },
  store,
  methods: {
    ...mapActions(["setProducts"]),
    //  Creates the URL slug used to identify the category
    returnPath(name) {
      const regex = /[^a-zA-Z0-9$ ]/g;
      return `/${name
        .replace(".", "$")
        .replace(regex, "")
        .split(" ")
        .map((el) => el.toLowerCase())
        .join("-")
        .replace("--", "-")}`;
    },
    //
    async getProducts() {
      if (this.products.length !== 0) return;
      this.loadingTable = true;
      const docRef = db
        .collection("products")
        .where("product_status", "==", "Available");
      try {
        const snapshot = await docRef.get();
        // Filters and maps doc.data() to new array
        this.products = snapshot.docs
          .reduce((acc, doc) => {
            // Check if the product category has been made available on the website
            // && that the product hasn't already been listed
            const query =
              this.filteredCategories.includes(doc.data().product_category) &&
              !doc.data().ecommerce;
            if (query) {
              acc.push({
                ...doc.data(),
                path: this.returnPath(doc.data().product_name),
              });
            }
            return acc;
          }, [])
          .sort((a, b) => (a.product_name > b.product_name ? 1 : -1));
        this.loadingTable = false;
      } catch (error) {
        this.errorAlert = true;
        this.errorMessage = error;
        this.loadingTable = false;
      }
    },
    //
    async saveProducts() {
      this.errorAlert = false;
      this.loadingBtn = true;
      const batch = db.batch();
      this.selectedProducts.forEach((item) => {
        // 1) Update ecommerce status on ERP product
        const docRef = db.collection("products").doc(item.product_id);
        batch.update(docRef, { ecommerce: true });
        // 2) Create ecommerce product in database
        const ecomRef = db
          .collection("ecommerce_products")
          .doc(item.product_id);
        batch.set(ecomRef, {
          id: ecomRef.id,
          name: item.product_name,
          path: item.path,
          category: item.product_category,
          price: {},
          images: [],
          rating: null,
          description: null,
          seoTitle: null,
          seoDescription: null,
        });
      });
      try {
        await batch.commit();
        showSnackbar("Products successfully added to website!");
        this.$emit("productSubmitted");
        this.setProducts();
        this.$emit("closeDialog");
        this.loadingBtn = false;
        this.products = [];
        this.selectedProducts = [];
      } catch (error) {
        this.errorAlert = true;
        this.errorMessage = error;
        this.loadingBtn = false;
      }
    },
  },
};
</script>